import { Absence, AbsenceDayDuration } from "@farmact/model/src/model/Absence";
import dayjs from "dayjs";
import { IconWithTooltip } from "@/components/IconWithTooltip/IconWithTooltip";
import { getAbsenceIcon, getAbsenceTypeName } from "@/util/absenceUtils";
import { computed } from "@/util/functions";
import "@/components/organization/sharedComponents/TimeTrackingsTable/TableGroup/TableGroupTopLevel/tableGroupTopLevel.scss";

type TableGroupAbsenceProps = {
    absence: Absence;
    date: Date;
};

export const TableGroupAbsence = (props: TableGroupAbsenceProps) => {
    return (
        <div className="table-group-top-level">
            <div className="table-group-top-level__cell table-group-top-level__date">
                <IconWithTooltip icon={getAbsenceIcon(props.absence.type)} iconClassName="group-icon" color="primary">
                    {props.absence.note || getAbsenceTypeName(props.absence.type)}
                </IconWithTooltip>

                <span className="group-date__day">{formatDay(props.date)}</span>
                <span className="group-date__date">{formatDate(props.date)}</span>
            </div>
            <AbsenceDurationCell absence={props.absence} date={props.date} />
            <div className="table-group-top-level__cell table-group-top-level__time" />
            <div className="table-group-top-level__cell table-group-top-level__break" />
            <div className="table-group-top-level__cell table-group-top-level__total" />
            <div className="table-group-top-level__cell table-group-top-level__activities" />
            <div className="table-group-top-level__cell table-group-top-level__notes">{props.absence.note}</div>
            <div className="table-group-top-level__cell" />
        </div>
    );
};

function formatDay(day: Date): string {
    return dayjs(day).locale("de").format("dd.");
}

function formatDate(day: Date): string {
    return dayjs(day).locale("de").format("DD.MM");
}

type AbsenceDurationCellProps = {
    absence: Absence;
    date: Date;
};
function AbsenceDurationCell(props: AbsenceDurationCellProps) {
    const content = computed(() => {
        const isSameDay = dayjs(props.absence.startDate).isSame(props.absence.endDate, "day");
        if (isSameDay) {
            const isVisibleDay = dayjs(props.absence.startDate).isSame(props.date, "date");
            if (isVisibleDay) {
                return formatDuration(props.absence.startDuration);
            }

            return;
        }

        const isFirstDay = dayjs(props.absence.startDate).isSame(props.date, "date");
        if (isFirstDay) {
            return formatDuration(props.absence.startDuration);
        }

        const isLastDay = dayjs(props.absence.endDate).isSame(props.date, "date");
        if (isLastDay) {
            return formatDuration(props.absence.endDuration);
        }
    });

    if (!content) {
        return <div className="table-group-top-level__cell" />;
    }

    return <div className="table-group-top-level__cell table-group-top-level__work-time">{content}</div>;
}

function formatDuration(duration: AbsenceDayDuration): React.ReactNode {
    switch (duration) {
        case AbsenceDayDuration.FIRST_HALF:
            return (
                <>
                    <span>00:00</span>
                    <span>-</span>
                    <span>12:00</span>
                </>
            );
        case AbsenceDayDuration.SECOND_HALF:
            return (
                <>
                    <span>12:00</span>
                    <span>-</span>
                    <span>23:59</span>
                </>
            );
        case AbsenceDayDuration.FULL:
            return null;
        default:
            duration satisfies never;
    }
}
