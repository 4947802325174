import { Order } from "@farmact/model/src/model/Order";
import { useOrganizationStore } from "@/components/organization/stores/organizationStore";
import { computed } from "@/util/functions";
import { getOrderMachines } from "@/util/orderUtils";

export function useOrderMachines(order: Order | undefined) {
    const machines = useOrganizationStore(s => s.machines);

    return computed(() => {
        if (!order) {
            return [];
        }

        return getOrderMachines(order, { includePastSnapshots: true, context: { machines } });
    });
}
