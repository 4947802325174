import { TimeTrackingRestrictionType } from "@farmact/model/src/model/AppCompany";
import { Customer } from "@farmact/model/src/model/Customer";
import { Employee } from "@farmact/model/src/model/Employee";
import { isField } from "@farmact/model/src/model/Field";
import { Machine, MachineVariantIdentifier } from "@farmact/model/src/model/Machine";
import { isMarker } from "@farmact/model/src/model/Marker";
import { Order } from "@farmact/model/src/model/Order";
import { OrderStatus } from "@farmact/model/src/model/OrderStatus";
import { Role } from "@farmact/model/src/model/Role";
import { TimeTracking, TimeTrackingMapStructureType, TimeTrackingOrder } from "@farmact/model/src/model/TimeTracking";
import { isTrack } from "@farmact/model/src/model/Track";
import { IonButton } from "@ionic/react";
import { ComboboxItem } from "@mantine/core";
import dayjs from "dayjs";
import { FormEvent, useMemo, useRef, useState } from "react";
import { useAppCompanyContext } from "@/components/authentication/useAppCompanyContext";
import { useLoadingOrUnloadingPointsForOrderBasics } from "@/components/campaigns/CampaignDetailsPage/data/useLoadingOrUnloadingPointsForOrderBasics";
import * as Collapsible from "@/components/FaCollapsible";
import * as Item from "@/components/FaItem";
import { FaItemGroup } from "@/components/FaItemGroup/FaItemGroup";
import * as Modal from "@/components/FaModal";
import { FaSegment } from "@/components/FaSegment/FaSegment";
import { Hint } from "@/components/hint/Hint";
import { SingleCustomerSelect } from "@/components/inputs/customerInputs/SingleCustomerSelect";
import { DateTimeInput } from "@/components/inputs/DateTimeInput/DateTimeInput";
import { SingleEmployeeSelect } from "@/components/inputs/employeeInputs/SingleEmployeeSelect";
import { Input } from "@/components/inputs/Input/Input";
import { CollapsibleMachineVariantsInputItem } from "@/components/inputs/MachineVariantsInputItem/CollapsibleMachineVariantsInputItem/CollapsibleMachineVariantsInputItem";
import { SingleOperatingUnitSelect } from "@/components/inputs/operatingUnitInputs/SingleOperatingUnitSelect";
import { SingleSelect } from "@/components/inputs/SingleSelect/SingleSelect";
import { SubmitFormOnEnter } from "@/components/inputs/SubmitFormOnEnter";
import { useOrderContext } from "@/components/orders/OrderContext/useOrderContext";
import { constructMachineVariantsForNewOrderDataEntry } from "@/components/orders/utils/constructMachineVariantsForNewOrderDataEntry";
import { useOrder } from "@/components/orders/utils/useOrder";
import { useOrderMachines } from "@/components/orders/utils/useOrderMachines";
import { useOrganizationStore } from "@/components/organization/stores/organizationStore";
import { DeleteAlert } from "@/components/organization/tablePages/DeleteAlert/DeleteAlert";
import { useTimeTrackingsOverviewContext } from "@/components/timeTracking/TimeTrackingsOverviewContext/useTimeTrackingsOverviewContext";
import { useToastContext } from "@/components/ToastContext";
import { Firebase } from "@/firebase";
import { useFields } from "@/util/customHooks/fields/fieldHooks";
import { useLoadingOrUnloadingPoints } from "@/util/customHooks/loadingOrUnloadingPoints/loadingOrUnloadingPointHooks";
import { useOrderCustomers } from "@/util/customHooks/order/useOrderCustomers";
import { useTracks } from "@/util/customHooks/tracks/trackHooks";
import { useCanEditTimeTracking } from "@/util/customHooks/useCanEditTimeTracking";
import { useFormValidation } from "@/util/customHooks/useFormValidation";
import { useRole } from "@/util/customHooks/useRole";
import { useValidation } from "@/util/customHooks/validation/useValidation";
import { computed } from "@/util/functions";
import { intervalsOverlap } from "@/util/intervalsOverlap";
import { recordError } from "@/util/recordError";
import { SelectOption } from "@/util/select";
import { normalizeTimeTracking } from "@/util/timeTrackingUtils";
import { useUserOrders } from "./editTimeTrackingModalData";
import { getErrorMessages } from "./editTimeTrackingModalUtils";
import { useTimeTrackingValidation } from "./useTimeTrackingValidation";
import "./editTimeTrackingModal.scss";

type EditTimeTrackingModalProps = {
    timeTracking: TimeTracking | null;
    isOpen: boolean;
    onFormChange: (updatedValues: Partial<TimeTracking>) => void;
    onDidDismiss: () => void;
    onDidUpdate?: () => void;
    role: "add" | "edit";
    /**
     * Whether to disable order selection (use to predefine order and do not allow changes)
     * @default false
     */
    isWorkKindLocked?: boolean;
    /**
     * Whether the employee can be changed.
     */
    allowEmployeeInput: boolean;
};

export const EditTimeTrackingModal = (props: EditTimeTrackingModalProps) => {
    const { reloadTotalOvertime } = useTimeTrackingsOverviewContext();
    const { order: contextOrder, orderFields, orderTracks } = useOrderContext();
    const { onMessage: showMessage } = useToastContext();
    const { appCompany, operatingUnits, operatingUnitsIncludingArchived } = useAppCompanyContext();
    const employees = useOrganizationStore(s => s.employees);
    const machines = useOrganizationStore(s => s.machines);

    const { role } = useRole();

    const machineVariantsInputRef = useRef<Collapsible.Ref>(null);
    const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
    const [workKind, setWorkKind] = useState(getWorkKind(props.timeTracking));
    const [previousIsOpen, setPreviousIsOpen] = useState(false);
    const { hint: timeTrackingEditHint } = useCanEditTimeTracking(props.timeTracking);

    const isAdmin = role ? [Role.ADMIN, Role.OWNER].includes(role) : true;
    const timeTrackingEmployeeId = props.timeTracking?.employeeId;
    const timeTrackingEmployee = employees.find(employee => employee.id === timeTrackingEmployeeId);

    const timeTrackingOperatingUnit = operatingUnitsIncludingArchived.find(
        unit => unit.id === props.timeTracking?.operatingUnitId
    );
    const possibleTimeTrackingOperatingUnits = computed(() => {
        const timeTrackingEmployeeOperatingUnitsDraft = timeTrackingEmployee
            ? operatingUnits.filter(unit => timeTrackingEmployee.operatingUnitIds.includes(unit.id))
            : [];
        if (
            timeTrackingOperatingUnit &&
            !timeTrackingEmployeeOperatingUnitsDraft.find(unit => unit.id === timeTrackingOperatingUnit.id)
        ) {
            timeTrackingEmployeeOperatingUnitsDraft.push(timeTrackingOperatingUnit);
        }
        return timeTrackingEmployeeOperatingUnitsDraft;
    });
    const daysIntoPast = computed(() => {
        if (
            isAdmin ||
            appCompany?.settings.employeeSettings.timeTrackingEditRestriction.type === TimeTrackingRestrictionType.NONE
        ) {
            return 60;
        }
        if (
            appCompany?.settings.employeeSettings.timeTrackingEditRestriction.type === TimeTrackingRestrictionType.DAYS
        ) {
            return appCompany?.settings.employeeSettings.timeTrackingEditRestriction.days;
        }
        return 0;
    });

    const [userOrders] = useUserOrders(timeTrackingEmployeeId, daysIntoPast, !props.isWorkKindLocked);
    const [currentOrder] = useOrder(props.timeTracking?.order?.orderId);

    const { availableLoadingOrUnloadingPoints } = useLoadingOrUnloadingPointsForOrderBasics(
        currentOrder ?? new Order()
    );

    const showOperatingUnitSelect =
        possibleTimeTrackingOperatingUnits.length > 1 ||
        (possibleTimeTrackingOperatingUnits.length === 1 && !timeTrackingOperatingUnit);

    const additionalMapStructure = useMemo(() => {
        const mapStructureToFind = props.timeTracking?.order?.mapStructure;
        if (!mapStructureToFind) {
            return null;
        }
        if (mapStructureToFind.type === TimeTrackingMapStructureType.FIELD) {
            const field = orderFields.find(field => field.id === mapStructureToFind.fieldId);
            if (field) {
                return null;
            }
            return {
                type: TimeTrackingMapStructureType.FIELD,
                id: mapStructureToFind.fieldId,
            };
        } else if (mapStructureToFind.type === TimeTrackingMapStructureType.TRACK) {
            const track = orderTracks.find(track => track.id === mapStructureToFind.trackId);
            if (track) {
                return null;
            }
            return {
                type: TimeTrackingMapStructureType.TRACK,
                id: mapStructureToFind.trackId,
            };
        } else if (mapStructureToFind.type === TimeTrackingMapStructureType.LOADING_OR_UNLOADING_POINT) {
            const loadingOrUnloadingPoint = availableLoadingOrUnloadingPoints.find(
                point => point.id === mapStructureToFind.loadingOrUnloadingPointId
            );
            if (loadingOrUnloadingPoint) {
                return null;
            }
            return {
                type: TimeTrackingMapStructureType.LOADING_OR_UNLOADING_POINT,
                id: mapStructureToFind.loadingOrUnloadingPointId,
            };
        }
        return null;
    }, [props.timeTracking?.order?.mapStructure, orderFields, orderTracks, availableLoadingOrUnloadingPoints]);

    const { fields: additionalField } = useFields(
        additionalMapStructure && additionalMapStructure.type === TimeTrackingMapStructureType.FIELD
            ? [additionalMapStructure.id]
            : []
    );
    const { tracks: additionalTrack } = useTracks(
        additionalMapStructure && additionalMapStructure.type === TimeTrackingMapStructureType.TRACK
            ? [additionalMapStructure.id]
            : []
    );
    const { loadingOrUnloadingPoints: additionalLoadingOrUnloadingPoint } = useLoadingOrUnloadingPoints(
        additionalMapStructure &&
            additionalMapStructure.type === TimeTrackingMapStructureType.LOADING_OR_UNLOADING_POINT
            ? [additionalMapStructure.id]
            : []
    );

    if (!previousIsOpen && props.isOpen) {
        setPreviousIsOpen(props.isOpen);
        setWorkKind(getWorkKind(props.timeTracking));
    }

    if (previousIsOpen && !props.isOpen) {
        setPreviousIsOpen(props.isOpen);
        setWorkKind("internal");
    }

    // if used outside an order context, order.decoupleTimeTrackings is false as per the default constructor
    const timeTrackingIsDecoupledOrderTimeTracking = contextOrder.decoupleTimeTrackings;

    const { validation: timeTrackingValidation, getOverlappingTimeTrackingsWithOrders } = useTimeTrackingValidation(
        props.timeTracking,
        !timeTrackingIsDecoupledOrderTimeTracking
    );

    const workKindValidation = useFormValidation({
        customerOrder: useValidation(props.timeTracking?.order, timeTrackingOrder => {
            if (workKind !== "customer") {
                return;
            }

            if (!timeTrackingOrder) {
                return "invalid_customer_order";
            }
            if (!timeTrackingOrder?.orderId) {
                return "missing_order";
            }
            if (!timeTrackingOrder?.customerId && currentOrder && currentOrder.customerIds.length > 0) {
                return "missing_customer";
            }
        }),
        customerWork: useValidation(props.timeTracking?.orderActivityPresetId, presetId => {
            if (workKind === "customer" && !presetId) {
                return "invalid_customer_workkind";
            }
        }),
        internal: useValidation(props.timeTracking?.internalActivityPresetId, intenralActivityPresetId => {
            if (workKind === "internal" && !intenralActivityPresetId) {
                return "invalid_internal_timetracking";
            }
        }),
    });

    const machineVariantsValidation = useValidation(props.timeTracking?.order?.machineVariants, variants => {
        if (variants && variants.some(variant => !variant.variantId)) {
            return "variant_not_selected";
        }
    });

    const handleDismiss = () => {
        timeTrackingValidation.reset();
        workKindValidation.resetForm();
        machineVariantsValidation.reset();
        props.onDidDismiss();
    };

    const handleSubmit = async (e: FormEvent) => {
        if (!appCompany) {
            return;
        }

        e.preventDefault();
        if (!props.timeTracking || !timeTrackingEmployeeId) {
            recordError("Error when adding/editing time tracking.", {
                timeTracking: props.timeTracking,
            });
            showMessage("Konnte Mitarbeiterdaten nicht finden. Bitte versuche es später erneut.", "danger");
            return;
        }
        if (!timeTrackingValidation.validate()) {
            if (timeTrackingValidation.error === "end_before_start") {
                showMessage("Die Endzeit darf nicht vor der Startzeit liegen", "warning");
                return;
            }
            if (timeTrackingValidation.error === "intersects_existing_timeTracking") {
                showMessage("Die Zeiten überschneiden sich mit anderen Zeiterfassungen", "warning");
                return;
            }
            if (timeTrackingValidation.error === "timeTracking_too_long_ago") {
                showMessage(timeTrackingEditHint, "warning");
                return;
            }
        }

        if (!workKindValidation.checkIsFormValid()) {
            showMessage("Bitte fülle zuerst die Tätigkeit bzw. den Auftrag aus.", "warning");
            return;
        }

        if (!machineVariantsValidation.validate()) {
            showMessage("Bitte wähle für jede Maschine eine Variante aus.", "warning");
            machineVariantsInputRef.current?.open();
            return;
        }

        let timeTrackingToSubmit: TimeTracking = normalizeTimeTracking(props.timeTracking);

        /**
         * Reset other fields
         */
        if (workKind === "customer") {
            timeTrackingToSubmit = {
                ...timeTrackingToSubmit,
                internalActivityPresetId: null,
            };
        } else {
            timeTrackingToSubmit = { ...timeTrackingToSubmit, order: null, orderActivityPresetId: null };
        }

        try {
            // if used outside an order context, order.decoupleTimeTrackings is false as per the default constructor
            const forOrderWithDecoupledTimeTrackings = contextOrder.decoupleTimeTrackings ? contextOrder.id : null;
            if (timeTrackingToSubmit.id) {
                Firebase.instance()
                    .updateWholeTimeTracking(timeTrackingToSubmit, forOrderWithDecoupledTimeTrackings, appCompany)
                    .then(reloadTotalOvertime);
            } else {
                Firebase.instance()
                    .createTimeTracking(timeTrackingToSubmit, forOrderWithDecoupledTimeTrackings, appCompany)
                    .then(reloadTotalOvertime);
            }

            props.onDidUpdate?.();
            handleDismiss();
        } catch (error: any) {
            if (error.message === "invalid-datetimes") {
                showMessage("Startzeit muss vor Endzeit liegen!", "warning");
            } else {
                showMessage(`Unbekannter Fehler: ${error}`, "danger");
            }
        }
    };

    const handleTimeTrackingDelete = () => {
        if (!props.timeTracking) {
            return;
        }

        Firebase.instance()
            .deleteTimeTracking(props.timeTracking.id, contextOrder.decoupleTimeTrackings ? contextOrder.id : null)
            .then(reloadTotalOvertime);
        handleDismiss();
    };

    const relevantOrders = [...userOrders].filter(
        order => !(order.status === OrderStatus.CHECKED || order.status === OrderStatus.BILLED) || isAdmin
    );
    if (currentOrder && !userOrders.map(order => order.id).includes(currentOrder.id)) {
        relevantOrders.push(currentOrder);
    }

    const orderOptions = relevantOrders
        .map(order => ({
            label: `${order.orderNumber} - ${order.name}`,
            value: order.id,
        }))
        .sort((orderOption1, orderOption2) => orderOption1.label.localeCompare(orderOption2.label));

    const availableInternalWorkTypeOptions = (appCompany?.settings.internalActivityPresets ?? [])
        .filter(preset => !preset.archived || props.timeTracking?.internalActivityPresetId === preset.id)
        .map(preset => ({
            value: preset.id,
            label: preset.name,
        }));

    const availableOrderWorkTypeOptions: ComboboxItem[] = (appCompany?.settings.orderActivityPresets ?? [])
        .filter(preset => !preset.archived || props.timeTracking?.orderActivityPresetId === preset.id)
        .map(preset => ({
            value: preset.id,
            label: preset.name,
        }));

    const { customers: selectableCustomers } = useOrderCustomers(currentOrder);
    const orderMachines = useOrderMachines(currentOrder);

    const handleCustomerChange = (customerId: Customer["id"] | null) => {
        if (!props.timeTracking?.order) {
            recordError("Could not change customer when editing time tracking", {
                timeTracking: props.timeTracking,
                orderData: props.timeTracking?.order,
            });
            return;
        }
        props.onFormChange({
            order: {
                ...props.timeTracking.order,
                customerId,
                mapStructure: null,
            },
        });
    };

    const handleMachineVariantsChange = (machineVariants: MachineVariantIdentifier[]) => {
        if (!props.timeTracking?.order || !currentOrder) {
            recordError("Could not change machine variants when editing time tracking", {
                timeTracking: props.timeTracking,
                orderData: props.timeTracking?.order,
                currentOrderId: currentOrder?.id,
            });
            return;
        }
        props.onFormChange({
            order: {
                ...props.timeTracking.order,
                machineVariants,
            },
        });
    };

    const handleOrderChange = (orderId: Order["id"] | null) => {
        if (!orderId) {
            return;
        }
        const newOrder = relevantOrders.find(order => order.id === orderId);
        if (!newOrder) {
            return;
        }

        const previousTrackingOrder = props.timeTracking?.order;
        const previousSelectedCustomerId = previousTrackingOrder?.customerId;
        const previousSelectedMapStructure = previousTrackingOrder?.mapStructure;

        const orderMachines = computed(() => {
            const availableMachines: Machine[] = [...newOrder.machineSnapshots];
            for (const machineId of newOrder.machineIds) {
                if (availableMachines.find(machineSnapshot => machineSnapshot.id === machineId)) {
                    continue;
                }
                const machine = machines.find(machine => machine.id === machineId);
                if (machine) {
                    availableMachines.push(machine);
                }
            }
            return availableMachines;
        });
        const timeTrackingOrderUpdateObject: TimeTrackingOrder = {
            orderId,
            orderArchived: newOrder.archived,
            orderNumber: newOrder.orderNumber,
            orderRunId: null,
            customerId: null,
            serviceId: newOrder.serviceId,
            mapStructure: null,
            machineVariants: constructMachineVariantsForNewOrderDataEntry(newOrder, orderMachines),
        };

        if (newOrder.customerIds.length === 1) {
            timeTrackingOrderUpdateObject.customerId = newOrder.customerIds[0];
        } else if (previousSelectedCustomerId && newOrder.customerIds.includes(previousSelectedCustomerId)) {
            timeTrackingOrderUpdateObject.customerId = previousSelectedCustomerId;
        } else {
            timeTrackingOrderUpdateObject.customerId = null;
        }

        if (previousSelectedMapStructure) {
            if (
                previousSelectedMapStructure.type === TimeTrackingMapStructureType.FIELD &&
                newOrder.fieldIds.includes(previousSelectedMapStructure.fieldId)
            ) {
                timeTrackingOrderUpdateObject.mapStructure = previousSelectedMapStructure;
            }
        }

        props.onFormChange({
            order: timeTrackingOrderUpdateObject,
            operatingUnitId: newOrder.operatingUnitId,
        });
    };

    const handleMapStructureChange = (mapStructureId: string | null) => {
        const timeTrackingOrder = props.timeTracking?.order;
        if (!timeTrackingOrder) {
            return;
        }

        if (!mapStructureId) {
            props.onFormChange({
                order: {
                    ...timeTrackingOrder,
                    mapStructure: null,
                },
            });
            return;
        }

        const mapStructure = [
            ...orderFields,
            ...orderTracks,
            ...(currentOrder?.markers ?? []),
            ...availableLoadingOrUnloadingPoints,
        ].find(mapStructure => {
            return mapStructure.id === mapStructureId;
        });

        if (!mapStructure) {
            return;
        }

        const timeTrackingOrderUpdateData: Partial<TimeTrackingOrder> = {};

        if (mapStructure.customerId !== timeTrackingOrder.customerId) {
            timeTrackingOrderUpdateData.customerId = mapStructure.customerId;
        }

        const newMapStructure: TimeTrackingOrder["mapStructure"] = computed(() => {
            if (isField(mapStructure)) {
                return {
                    type: TimeTrackingMapStructureType.FIELD,
                    fieldId: mapStructure.id,
                };
            } else if (isMarker(mapStructure)) {
                return {
                    type: TimeTrackingMapStructureType.MARKER,
                    markerId: mapStructure.id,
                };
            } else {
                return {
                    type: TimeTrackingMapStructureType.TRACK,
                    trackId: mapStructure.id,
                };
            }
        });

        props.onFormChange({
            order: {
                ...timeTrackingOrder,
                ...timeTrackingOrderUpdateData,
                mapStructure: newMapStructure,
            },
        });
    };

    const handleEmployeeChange = (newEmployee: Employee | null) => {
        if (!newEmployee) {
            return;
        }
        const updateObject: Partial<TimeTracking> = {
            employeeId: newEmployee.id,
            appUserId: newEmployee.appUserId ?? "",
        };
        if (
            props.timeTracking?.internalActivityPresetId &&
            props.timeTracking?.operatingUnitId &&
            !newEmployee.operatingUnitIds.includes(props.timeTracking.operatingUnitId)
        ) {
            if (newEmployee.operatingUnitIds.length === 1) {
                updateObject.operatingUnitId = newEmployee.operatingUnitIds[0];
            } else {
                updateObject.operatingUnitId = null;
            }
        }
        props.onFormChange(updateObject);
    };

    const selectedOrder = relevantOrders.find(order => {
        return order.id === props.timeTracking?.order?.orderId;
    });

    const selectedMapStructureId = computed((): string | null => {
        const mapStructure = props.timeTracking?.order?.mapStructure;
        if (!mapStructure) {
            return null;
        }

        if (mapStructure.type === TimeTrackingMapStructureType.FIELD) {
            return mapStructure.fieldId;
        }
        if (mapStructure.type === TimeTrackingMapStructureType.MARKER) {
            return mapStructure.markerId;
        }
        if (mapStructure.type === TimeTrackingMapStructureType.TRACK) {
            return mapStructure.trackId;
        }
        if (mapStructure.type === TimeTrackingMapStructureType.LOADING_OR_UNLOADING_POINT) {
            return mapStructure.loadingOrUnloadingPointId;
        }
        return null;
    });

    const timeTrackingDaysRestriction =
        appCompany?.settings.employeeSettings.timeTrackingEditRestriction.type === TimeTrackingRestrictionType.DAYS
            ? appCompany?.settings.employeeSettings.timeTrackingEditRestriction.days
            : undefined;
    const hasRestrictedDateInput = timeTrackingDaysRestriction && !isAdmin;
    const minAvailableDateTime = hasRestrictedDateInput
        ? dayjs().subtract(timeTrackingDaysRestriction, "day").toDate()
        : undefined;
    const maxAvailableDateTime = hasRestrictedDateInput
        ? dayjs().add(timeTrackingDaysRestriction, "day").toDate()
        : undefined;

    const warningTooltip = computed(() => {
        if (!timeTrackingEmployee?.currentActivity || !props.timeTracking) {
            return false;
        }

        const overlapsWithCurrentActivity = intervalsOverlap(
            { startDateTime: timeTrackingEmployee.currentActivity.startDateTime, endDateTime: dayjs().toISOString() },
            props.timeTracking
        );

        if (overlapsWithCurrentActivity) {
            return `Die Zeiterfassung übeschneidet sich mit der aktuellen Aktivität (läuft seit ${dayjs(
                timeTrackingEmployee?.currentActivity?.startDateTime
            ).format("DD.MM., HH:mm")}).`;
        }
    });

    const internalActivityName = computed(() => {
        if (!props.timeTracking?.internalActivityPresetId) {
            return;
        }

        const preset = appCompany?.settings.internalActivityPresets.find(preset => {
            return preset.id === props.timeTracking?.internalActivityPresetId;
        });
        return preset?.name ?? "Unbekannte Aktivität";
    });

    const mapStructureSelectOptions: SelectOption[] = [
        ...orderFields,
        ...orderTracks,
        ...(currentOrder?.markers ?? []),
        ...availableLoadingOrUnloadingPoints,
        ...additionalLoadingOrUnloadingPoint,
        ...additionalField,
        ...additionalTrack,
    ].map(structure => {
        if (isMarker(structure)) {
            return {
                group: "Marker",
                value: structure.id,
                label: structure.label,
            };
        } else if (isField(structure)) {
            return {
                group: "Felder",
                value: structure.id,
                label: structure.name,
            };
        } else if (isTrack(structure)) {
            return {
                group: "Strecken",
                value: structure.id,
                label: structure.name,
            };
        } else {
            return {
                group: "Lade-/Entladepunkte",
                value: structure.id,
                label: structure.name,
            };
        }
    });

    return (
        <>
            <Modal.Root isOpen={props.isOpen} onDismiss={handleDismiss} className="edit-time-tracking-modal">
                <Modal.Header>Zeiterfassung {props.role === "add" ? "hinzufügen" : "bearbeiten"}</Modal.Header>
                <Modal.Content>
                    <form onSubmit={handleSubmit}>
                        <SubmitFormOnEnter />
                        <FaItemGroup>
                            <Item.Root
                                error={timeTrackingValidation.error}
                                state={warningTooltip ? "warning" : undefined}>
                                <Item.Label warningTooltip={warningTooltip}>Start</Item.Label>
                                <Item.Content>
                                    <DateTimeInput
                                        min={minAvailableDateTime}
                                        max={maxAvailableDateTime}
                                        value={
                                            props.timeTracking?.startDateTime
                                                ? dayjs(props.timeTracking?.startDateTime)
                                                : null
                                        }
                                        onValueChange={startDateTime => {
                                            props.onFormChange({ startDateTime: startDateTime.toISOString() });
                                        }}
                                    />
                                </Item.Content>
                            </Item.Root>
                            <Item.Root
                                error={timeTrackingValidation.error}
                                state={warningTooltip ? "warning" : undefined}
                                className="edit-time-tracking-modal__end-item">
                                <Item.Label warningTooltip={warningTooltip}>Ende</Item.Label>
                                <Item.Content>
                                    <DateTimeInput
                                        min={minAvailableDateTime}
                                        max={maxAvailableDateTime}
                                        value={
                                            props.timeTracking?.endDateTime
                                                ? dayjs(props.timeTracking?.endDateTime)
                                                : null
                                        }
                                        onValueChange={endDateTime => {
                                            props.onFormChange({ endDateTime: endDateTime.toISOString() });
                                        }}
                                    />
                                </Item.Content>
                            </Item.Root>
                        </FaItemGroup>
                        {timeTrackingValidation.error === "intersects_existing_timeTracking" && (
                            <Hint color="danger" severity="error" className="edit-time-tracking-modal__hint">
                                Die Zeiten überschneiden sich mit anderen Zeiterfassungen:
                                <ul>
                                    {getErrorMessages(
                                        getOverlappingTimeTrackingsWithOrders(),
                                        props.timeTracking,
                                        appCompany
                                    ).map((message, index) => (
                                        <li key={index}>{message}</li>
                                    ))}
                                </ul>
                            </Hint>
                        )}
                        {timeTrackingValidation.error === "timeTracking_too_long_ago" && (
                            <>
                                <Hint color="danger" severity="error" className="edit-time-tracking-modal__hint">
                                    {timeTrackingEditHint}
                                </Hint>
                            </>
                        )}
                        <FaItemGroup>
                            {props.allowEmployeeInput && (
                                <Item.Root>
                                    <Item.Label>Mitarbeiter</Item.Label>
                                    <Item.Content>
                                        <SingleEmployeeSelect
                                            value={timeTrackingEmployeeId}
                                            onModelChange={handleEmployeeChange}
                                        />
                                    </Item.Content>
                                </Item.Root>
                            )}
                            {!props.isWorkKindLocked && (
                                <>
                                    <Item.Root>
                                        <Item.Label>Arbeitstyp</Item.Label>
                                        <Item.Content>
                                            <FaSegment
                                                value={workKind}
                                                onValueChange={value => setWorkKind(value as WorkKind)}
                                                options={[
                                                    { value: "customer", label: "Kunde" },
                                                    { value: "internal", label: "Intern" },
                                                ]}
                                            />
                                        </Item.Content>
                                    </Item.Root>

                                    {workKind === "customer" && (
                                        <Item.Root error={workKindValidation.fields.customerOrder.error}>
                                            <Item.Label>Auftrag</Item.Label>
                                            <Item.Content>
                                                <SingleSelect
                                                    placeholder="Auftrag auswählen"
                                                    data={orderOptions}
                                                    value={props.timeTracking?.order?.orderId ?? null}
                                                    onChange={handleOrderChange}
                                                    dropdownPosition="top"
                                                />
                                            </Item.Content>
                                        </Item.Root>
                                    )}
                                </>
                            )}

                            {workKind === "internal" && (
                                <>
                                    <Item.Root error={workKindValidation.fields.internal.error}>
                                        <Item.Label>Interne Arbeit</Item.Label>
                                        <Item.Content>
                                            <SingleSelect
                                                placeholder="Interne Arbeit"
                                                data={availableInternalWorkTypeOptions}
                                                value={props.timeTracking?.internalActivityPresetId ?? undefined}
                                                onChange={newValue =>
                                                    props.onFormChange({
                                                        internalActivityPresetId: newValue,
                                                    })
                                                }
                                            />
                                        </Item.Content>
                                    </Item.Root>
                                    {appCompany?.settings.employeeSettings.queryOperatingUnitForInternalWork && (
                                        <Item.Root>
                                            <Item.Label>Betrieb</Item.Label>
                                            <Item.Content>
                                                {showOperatingUnitSelect ? (
                                                    <SingleOperatingUnitSelect
                                                        value={props.timeTracking?.operatingUnitId}
                                                        data={possibleTimeTrackingOperatingUnits}
                                                        onValueChange={newOperatingUnitId =>
                                                            props.onFormChange({ operatingUnitId: newOperatingUnitId })
                                                        }
                                                    />
                                                ) : (
                                                    <span>{timeTrackingOperatingUnit?.name ?? "-"}</span>
                                                )}
                                            </Item.Content>
                                        </Item.Root>
                                    )}
                                </>
                            )}
                            {workKind === "customer" && (
                                <>
                                    <Item.Root error={workKindValidation.fields.customerWork.error}>
                                        <Item.Label>Tätigkeit</Item.Label>
                                        <Item.Content>
                                            <SingleSelect
                                                placeholder="Tätigkeit"
                                                data={availableOrderWorkTypeOptions}
                                                value={props.timeTracking?.orderActivityPresetId ?? ""}
                                                onChange={newValue =>
                                                    props.onFormChange({
                                                        orderActivityPresetId: newValue,
                                                    })
                                                }
                                            />
                                        </Item.Content>
                                    </Item.Root>

                                    {selectableCustomers.length > 1 && (
                                        <Item.Root>
                                            <Item.Label>Kunde</Item.Label>
                                            <Item.Content>
                                                <SingleCustomerSelect
                                                    data={selectableCustomers}
                                                    error={workKindValidation.fields.customerOrder.error}
                                                    value={props.timeTracking?.order?.customerId}
                                                    onValueChange={handleCustomerChange}
                                                />
                                            </Item.Content>
                                        </Item.Root>
                                    )}
                                </>
                            )}

                            {workKind === "customer" && (
                                <Item.Root>
                                    <Item.Label>Objekt</Item.Label>
                                    <Item.Content>
                                        <SingleSelect
                                            placeholder="Objekt"
                                            data={mapStructureSelectOptions}
                                            value={selectedMapStructureId}
                                            onChange={handleMapStructureChange}
                                        />
                                    </Item.Content>
                                </Item.Root>
                            )}
                            <Item.Root>
                                <Item.Label>Notiz</Item.Label>
                                <Item.Content>
                                    <Input
                                        placeholder="Notiz"
                                        value={props.timeTracking?.note}
                                        onChange={event => {
                                            props.onFormChange({ note: event.currentTarget.value });
                                        }}
                                    />
                                </Item.Content>
                            </Item.Root>

                            {selectedOrder?.decoupleTimeTrackings && (
                                <Hint color="warning" severity="info" className="edit-time-tracking-modal__hint">
                                    {timeTrackingIsDecoupledOrderTimeTracking
                                        ? "Diese Zeiterfassung wirkt sich nicht auf die Mitarbeiterstunden aus, da die Zeiten entkoppelt sind."
                                        : "Diese Zeiterfassung wirkt sich nicht auf den Auftrag aus, da die Zeiten entkoppelt sind."}
                                </Hint>
                            )}

                            {props.isWorkKindLocked && workKind === "internal" && (
                                <Item.Root>
                                    <Item.Label>Intern</Item.Label>
                                    <Item.Content>
                                        <span>{internalActivityName}</span>
                                    </Item.Content>
                                </Item.Root>
                            )}
                            {props.isWorkKindLocked && workKind === "customer" && (
                                <Item.Root>
                                    <Item.Label>Auftrag</Item.Label>
                                    <Item.Content>
                                        <span>{currentOrder?.name ?? "Unbekannter Auftrag"}</span>
                                    </Item.Content>
                                </Item.Root>
                            )}
                            {props.timeTracking?.order && orderMachines.length > 0 && (
                                <CollapsibleMachineVariantsInputItem
                                    ref={machineVariantsInputRef}
                                    availableMachines={orderMachines}
                                    selectedMachineVariants={props.timeTracking.order.machineVariants}
                                    onChange={handleMachineVariantsChange}
                                    error={machineVariantsValidation.error}
                                />
                            )}
                        </FaItemGroup>
                    </form>
                </Modal.Content>
                <Modal.Footer>
                    {props.role === "edit" && (
                        <IonButton color="danger" onClick={() => setDeleteAlertOpen(true)}>
                            Löschen
                        </IonButton>
                    )}
                    <IonButton color="dark" onClick={handleDismiss}>
                        Verwerfen
                    </IonButton>
                    <IonButton color="primary" onClick={handleSubmit}>
                        Speichern
                    </IonButton>
                </Modal.Footer>
            </Modal.Root>
            <DeleteAlert
                onDelete={handleTimeTrackingDelete}
                header="Zeiterfassung löschen"
                onDidDismiss={() => setDeleteAlertOpen(false)}
                show={deleteAlertOpen}
                message="Möchtest du diese Zeiterfassung wirklich löschen?"
            />
        </>
    );
};

type WorkKind = "customer" | "internal";

function getWorkKind(timeTracking: TimeTracking | null): WorkKind {
    if (!timeTracking) {
        return "internal";
    }
    if (timeTracking.orderActivityPresetId || timeTracking.order?.orderId) {
        return "customer";
    }

    return "internal";
}
