import { Employee } from "@farmact/model/src/model/Employee";
import { SingleSelect } from "@/components/inputs/SingleSelect/SingleSelect";
import { useOrganizationStore } from "@/components/organization/stores/organizationStore";
import { useFilterEmployeeHasAccess } from "@/util/customHooks/useFilterEmployeeHasAccess";
import { SingleSelectProps, getModelId, getSingleSelectChangeHandler, useSelectOptions } from "@/util/select";
import { createEmployeeSelectItem, defaultSelectableEmployeesSorter } from "./employeeInputsUtils";

export type SingleEmployeeSelectProps = SingleSelectProps<Employee> & { disableInactive?: boolean };

export const SingleEmployeeSelect = (props: SingleEmployeeSelectProps) => {
    const {
        placeholder = "Mitarbeiter auswählen",
        data: controlledEmployees,
        optionCreator = employee => createEmployeeSelectItem(employee, { disableInactive: props.disableInactive }),
        sorter = defaultSelectableEmployeesSorter,
        value,
        onValueChange,
        onModelChange,
        ...restProps
    } = props;

    const uncontrolledEmployees = useOrganizationStore(s => s.employees);

    const employees = controlledEmployees || uncontrolledEmployees;

    const selectableEmployees = useFilterEmployeeHasAccess(employees, employee => {
        if (value && getModelId(value) === employee.id) {
            return true;
        }
        return employee.operatingUnitIds;
    });

    const items = useSelectOptions(selectableEmployees, {
        optionCreator,
        sorter,
    });

    return (
        <SingleSelect
            {...restProps}
            placeholder={placeholder}
            searchPlaceholder="Mitarbeiter durchsuchen"
            data={items}
            value={value ? getModelId(value) : null}
            onChange={getSingleSelectChangeHandler(selectableEmployees, {
                onValueChange,
                onModelChange,
            })}
        />
    );
};
