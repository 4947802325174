import { getWorkDaysBetweenTwoDates } from "@/components/organization/sharedComponents/TimeTrackingsSummary/utils/getTargetHours";
import { getWorkDaysForAbsencesBetweenDates } from "@/util/absenceUtils";
import { GetEmployeeTimesSummaryParams } from "@/util/employeeUtils";
import { _getEmployeeTargetHours } from "./_getEmployeeTargetHours";

export function _getEmployeeAbsenceMinutes(
    params: Pick<GetEmployeeTimesSummaryParams, "interval" | "absences" | "employee">
): number {
    const {
        interval: [start, end],
        absences,
    } = params;

    const workDays = getWorkDaysBetweenTwoDates(start, end, {
        exact: false,
    });
    if (workDays <= 0) {
        return 0;
    }

    const targetHours = _getEmployeeTargetHours(params);

    const absencesDays = getWorkDaysForAbsencesBetweenDates(absences, start, end);

    return absencesDays * (targetHours / workDays) * 60;
}
