import { Absence, AbsenceDayDuration, AbsenceType } from "@farmact/model/src/model/Absence";
import { computeDates } from "@farmact/model/src/utils/computeDates";
import { IconDefinition, faBabyCarriage, faInfoCircle, faPlane } from "@fortawesome/free-solid-svg-icons";
import { faFaceThermometer, faPersonToDoor, faSchool } from "@fortawesome/pro-solid-svg-icons";
import dayjs, { Dayjs } from "dayjs";
import isSameOrAfterPlugin from "dayjs/plugin/isSameOrAfter";
import isSameOrBeforePlugin from "dayjs/plugin/isSameOrBefore";
import { produce } from "immer";
import { getWorkDaysBetweenTwoDates } from "@/components/organization/sharedComponents/TimeTrackingsSummary/utils/getTargetHours";
import { DateLike } from "./timeUtils";

dayjs.extend(isSameOrBeforePlugin);
dayjs.extend(isSameOrAfterPlugin);

export function clipAbsences(absences: Absence[], startDate: Dayjs, endDate: Dayjs): Absence[] {
    const overlappingAbsences = absences.filter(absence => {
        return (
            dayjs(getAbsenceStart(absence)).isSameOrBefore(endDate, "date") &&
            dayjs(getAbsenceEnd(absence)).isSameOrAfter(startDate, "date")
        );
    });
    return overlappingAbsences.map(absence => clipAbsence(absence, startDate, endDate));
}

export function clipAbsence(absence: Absence, startDate: Dayjs, endDate: Dayjs): Absence {
    return produce({ ...absence }, draft => {
        if (dayjs(getAbsenceStart(draft)).isBefore(startDate, "date")) {
            draft.startDate = startDate.format("YYYY-MM-DD");
        }

        if (dayjs(getAbsenceEnd(draft)).isAfter(endDate, "date")) {
            draft.endDate = endDate.format("YYYY-MM-DD");
        }

        draft.dates = computeDates(getAbsenceStart(draft).toISOString(), getAbsenceEnd(draft).toISOString());
    });
}

export function getWorkDaysForAbsencesBetweenDates(absences: Absence[], start: DateLike, end: DateLike) {
    return clipAbsences(absences, dayjs(start), dayjs(end)).reduce((acc, curr) => {
        return (
            acc +
            getWorkDaysBetweenTwoDates(getAbsenceStart(curr), getAbsenceEnd(curr), {
                exact: true,
            })
        );
    }, 0);
}

export function getAbsenceTypeName(absenceType: AbsenceType) {
    switch (absenceType) {
        case AbsenceType.VACATION:
            return "Urlaub";
        case AbsenceType.SICK:
            return "Krankheit";
        case AbsenceType.OTHER:
            return "Sonstige Abwesenheit";
        case AbsenceType.PARENTAL_LEAVE:
            return "Elternzeit";
        case AbsenceType.TRAINING:
            return "Schulung";
        default:
            return "Abwesenheit";
    }
}

export function getAbsenceIcon(absenceType: AbsenceType): IconDefinition {
    switch (absenceType) {
        case AbsenceType.VACATION:
            return faPlane;
        case AbsenceType.SICK:
            return faFaceThermometer;
        case AbsenceType.PARENTAL_LEAVE:
            return faBabyCarriage;
        case AbsenceType.TRAINING:
            return faSchool;
        case AbsenceType.OTHER:
            return faPersonToDoor;
        default:
            return faInfoCircle;
    }
}

export function getAbsenceStart(absence: Absence): Date {
    switch (absence.startDuration) {
        case AbsenceDayDuration.FULL:
        case AbsenceDayDuration.FIRST_HALF:
            return dayjs(absence.startDate).startOf("day").toDate();
        case AbsenceDayDuration.SECOND_HALF:
            return dayjs(absence.startDate).hour(12).startOf("hour").toDate();
        default:
            absence.startDuration satisfies never;
            return new Date();
    }
}

export function getAbsenceEnd(absence: Absence): Date {
    const isSameDay = dayjs(absence.startDate).isSame(absence.endDate, "date");
    if (isSameDay) {
        switch (absence.startDuration) {
            case AbsenceDayDuration.FULL:
            case AbsenceDayDuration.SECOND_HALF:
                return dayjs(absence.startDate).endOf("day").toDate();
            case AbsenceDayDuration.FIRST_HALF:
                return dayjs(absence.startDate).hour(12).startOf("hour").toDate();
            default:
                absence.startDuration satisfies never;
                return new Date();
        }
    }

    switch (absence.endDuration) {
        case AbsenceDayDuration.FULL:
            return dayjs(absence.endDate).endOf("day").toDate();
        case AbsenceDayDuration.FIRST_HALF:
            return dayjs(absence.endDate).hour(12).startOf("hour").toDate();
        default:
            absence.endDuration satisfies never;
            return new Date();
    }
}
