import { OrderBasics } from "@farmact/model/src/model/OrderBasics";
import { useOrganizationStore } from "@/components/organization/stores/organizationStore";
import { useCustomersLoadingOrUnloadingPoints } from "@/util/customHooks/customers/useCustomersLoadingOrUnloadingPoints";
import { useLoadingOrUnloadingPoints } from "@/util/customHooks/loadingOrUnloadingPoints/loadingOrUnloadingPointHooks";
import { deduplicateById } from "@/util/deduplicateById";

export function useLoadingOrUnloadingPointsForOrderBasics(orderBasics: OrderBasics) {
    const commonLoadingPoints = useOrganizationStore(s => s.loadingOrUnloadingPoints);
    const { loadingOrUnloadingPoints: customerLoadingPoints } = useCustomersLoadingOrUnloadingPoints(
        orderBasics.customerIds
    );
    const { loadingOrUnloadingPoints: selectedLoadingPoints } = useLoadingOrUnloadingPoints(
        Array.from(new Set([...orderBasics.loadingPointIds, ...orderBasics.unloadingPointIds]))
    );
    const availableLoadingOrUnloadingPoints = deduplicateById([
        ...commonLoadingPoints,
        ...customerLoadingPoints,
        ...selectedLoadingPoints,
    ]);
    const incompatibleLoadingPoints =
        orderBasics.customerIds.length === 0
            ? []
            : availableLoadingOrUnloadingPoints.filter(
                  point => point.customerId && !orderBasics.customerIds.includes(point.customerId)
              );
    return {
        availableLoadingOrUnloadingPoints,
        incompatibleLoadingPoints,
    };
}
