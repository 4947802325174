import dayjs from "dayjs";
import { Employee } from "./Employee";

export class Absence {
    id: string;
    employeeId: Employee["id"];
    type: AbsenceType;

    /**
     * YYYY-MM-DD
     */
    startDate: string;
    /**
     * single day absences: FIRST_HALF, SECOND_HALF and FULL duration is legit (`endDuration` is unused)
     * mutli day absences: only SECOND_HALF and FULL duration is legit
     */
    startDuration: AbsenceDayDuration.FIRST_HALF | AbsenceDayDuration.SECOND_HALF | AbsenceDayDuration.FULL;
    /**
     * YYYY-MM-DD
     */
    endDate: string;
    /**
     * unused for single day absences (see `startDate` comment)
     */
    endDuration: AbsenceDayDuration.FIRST_HALF | AbsenceDayDuration.FULL;

    /**
     * used for querying
     */
    dates: string[];
    note: string;

    constructor(initialValues?: Partial<Absence>) {
        this.id = initialValues?.id ?? "";
        this.employeeId = initialValues?.employeeId ?? "";
        this.type = initialValues?.type ?? AbsenceType.VACATION;
        this.startDate = initialValues?.startDate ?? dayjs().add(1, "day").format("YYYY-MM-DD");
        this.startDuration = initialValues?.startDuration ?? AbsenceDayDuration.FULL;
        this.endDate = initialValues?.endDate ?? dayjs().add(1, "day").format("YYYY-MM-DD");
        this.endDuration = initialValues?.endDuration ?? AbsenceDayDuration.FULL;
        this.dates = initialValues?.dates ?? [];
        this.note = initialValues?.note ?? "";
    }
}

export enum AbsenceType {
    VACATION = "AbsenceType_VACATION",
    SICK = "AbsenceType_SICK",
    OTHER = "AbsenceType_OTHER",
    PARENTAL_LEAVE = "AbsenceType_PARENTAL_LEAVE",
    TRAINING = "AbsenceType_TRAINING",
}

export enum AbsenceDayDuration {
    FIRST_HALF = "AbsenceDayDuration_FIRST_HALF",
    SECOND_HALF = "AbsenceDayDuration_SECOND_HALF",
    FULL = "AbsenceDayDuration_FULL",
}
