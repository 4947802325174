export enum TaskRecordType {
    AREA = "AREA",
    TRACKS = "TRACKS",
    TONS = "TONS",
    CUBIC_METERS = "CUBIC_METERS",
    SOLID_CUBIC_METERS = "SOLID_CUBIC_METERS",
    LITER = "LITER",
    LOADS = "LOADS",
}

export function getTaskRecordTypeName(taskRecordType: TaskRecordType): string {
    switch (taskRecordType) {
        case TaskRecordType.AREA:
            return "Bearbeitete Fläche";
        case TaskRecordType.TRACKS:
            return "Bearbeitete Strecke";
        case TaskRecordType.TONS:
            return "Bearbeitete Tonnen";
        case TaskRecordType.CUBIC_METERS:
            return "Bearbeitete Kubikmeter";
        case TaskRecordType.SOLID_CUBIC_METERS:
            return "Bearbeitete Festmeter";
        case TaskRecordType.LITER:
            return "Bearbeitete Liter";
        case TaskRecordType.LOADS:
            return "Gefahrene Fuhren";
        default:
            taskRecordType satisfies never;
            return "";
    }
}
