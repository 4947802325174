export enum MarkerType {
    ORDER = "order",
    FIELD = "field",
    CUSTOMER = "customer",
    OTHER = "other",
    TRACKING = "tracking",
    DISABLED = "disabled",
    LOADING_POINT = "loading-point",
    UNLOADING_POINT = "unloading-point",
    LOADING_AND_UNLOADING_POINT = "loading-and-unloading-point",
}
