import { Employee } from "@farmact/model/src/model/Employee";
import { TimeTracking } from "@farmact/model/src/model/TimeTracking";
import dayjs, { Dayjs } from "dayjs";
import { Query, limit, orderBy, query, where } from "firebase/firestore";
import { useOrganizationStore } from "@/components/organization/stores/organizationStore";
import { Firebase } from "@/firebase";
import { useCollectionData } from "@/firebase/dataHooks";

export function useEmployeeStartDate(employeeId: Employee["id"] | undefined) {
    const employees = useOrganizationStore(s => s.employees);
    const [timeTrackings, timeTrackingsLoading] = useCollectionData(getFirstTimeTrackingQuery(employeeId), [
        employeeId,
    ]);
    const employee = employees.find(employee => employee.id === employeeId);
    const startDate = _getEmployeeStartDate({ employee, firstTimeTracking: timeTrackings[0] });
    return [startDate, !employee || timeTrackingsLoading] as const;
}

function getFirstTimeTrackingQuery(employeeId: string | undefined): Query<TimeTracking> | undefined {
    if (!employeeId) {
        return;
    }

    return query(
        Firebase.instance().getAllTimeTrackings(),
        where("employeeId", "==", employeeId),
        orderBy("startDateTime", "asc"),
        limit(1)
    );
}

export function _getEmployeeStartDate(params: {
    employee: Employee | undefined;
    firstTimeTracking: TimeTracking | undefined;
}): Dayjs | undefined {
    const { employee, firstTimeTracking } = params;

    if (employee?.hiringDate) {
        return dayjs(employee.hiringDate);
    }

    if (firstTimeTracking) {
        return dayjs(firstTimeTracking.startDateTime);
    }
}
