import { ActivityPreset } from "@farmact/model/src/model/AppCompany";
import Field from "@farmact/model/src/model/Field";
import { Machine } from "@farmact/model/src/model/Machine";
import { TaskRecord } from "@farmact/model/src/model/Order";
import { ServicePriceUnit } from "@farmact/model/src/model/services/ServicePriceUnit";
import { TaskRecordType } from "@farmact/model/src/model/services/TaskRecords";
import { TimeTracking } from "@farmact/model/src/model/TimeTracking";
import dayjs from "dayjs";
import duration, { DurationUnitType } from "dayjs/plugin/duration";

dayjs.extend(duration);

export function calculateAreaInHectars(fields: Field[]): number {
    return fields.reduce((prev, curr) => prev + curr.areaHa, 0);
}

export function calculateLengthTaskRecordsInMeters(taskRecords: TaskRecord[]) {
    return taskRecords
        .filter(taskRecord => taskRecord.type === TaskRecordType.TRACKS)
        .reduce((acc, curr) => {
            if (!curr.record) {
                return acc;
            }
            if (curr.unit === ServicePriceUnit.KILOMETER) {
                return acc + curr.record * 1000;
            } else if (curr.unit === ServicePriceUnit.METER) {
                return acc + curr.record;
            }
            return acc;
        }, 0);
}

export function calculateTonsTaskRecords(taskRecords: TaskRecord[]): number {
    return taskRecords
        .filter(taskRecord => taskRecord.type === TaskRecordType.TONS)
        .reduce((acc, curr) => acc + (curr.record ?? 0), 0);
}

export function calculateCubicMetersTaskRecords(taskRecords: TaskRecord[]): number {
    return taskRecords
        .filter(taskRecord => taskRecord.type === TaskRecordType.CUBIC_METERS)
        .reduce((acc, curr) => acc + (curr.record ?? 0), 0);
}

export function calculateSolidCubicMetersTaskRecords(taskRecords: TaskRecord[]): number {
    return taskRecords
        .filter(taskRecord => taskRecord.type === TaskRecordType.SOLID_CUBIC_METERS)
        .reduce((acc, curr) => acc + (curr.record ?? 0), 0);
}

export function calculateLoadsTaskRecords(taskRecords: TaskRecord[]): number {
    return taskRecords
        .filter(taskRecord => taskRecord.type === TaskRecordType.LOADS)
        .reduce((acc, curr) => acc + (curr.record ?? 0), 0);
}

export function calculateLiterTaskRecords(taskRecords: TaskRecord[]): number {
    return taskRecords
        .filter(taskRecord => taskRecord.type === TaskRecordType.LITER)
        .reduce((acc, curr) => acc + (curr.record ?? 0), 0);
}

function calculateCostForSingleMachine(machine: Machine, usageTimeHours: number, usageAreaHa: number): number {
    const hectarCost = usageAreaHa * machine.costPerHectar;
    const usageCost = usageTimeHours * machine.costPerUsageHour;
    // TODO
    const drivingCost = 0;
    return hectarCost + usageCost + drivingCost;
}

// FIXME not sure if every used machine should be multiplied by the total hour/area usage
export function calculateMachineCost(machines: Machine[], usageTimeHours: number, usageAreaHa: number): number {
    return machines
        .map(machine => {
            return calculateCostForSingleMachine(machine, usageTimeHours, usageAreaHa);
        })
        .reduce((prev, curr) => prev + curr, 0);
}

/**
 * Calculate the total tracked time spend for the given `workType`
 * @returns Tracked time in minutes
 */
export function calculateTimeTrackingAmountByActivityPreset(
    timeTrackings: TimeTracking[],
    presetId: ActivityPreset["id"]
): number {
    const trackings = timeTrackings.filter(timeTracking => {
        return timeTracking.orderActivityPresetId === presetId;
    });
    return calculateTimeTrackingAmount(trackings, "minutes");
}

/**
 * Calculate the total tracked time
 * @param timeTrackings
 * @param unit
 * @returns
 */
export function calculateTimeTrackingAmount(timeTrackings: TimeTracking[], unit: DurationUnitType = "minutes"): number {
    const timeInSeconds = timeTrackings.reduce((acc, cur) => {
        const diff = Math.abs(dayjs(cur.startDateTime).diff(cur.endDateTime, "seconds"));
        return acc + diff;
    }, 0);
    return dayjs.duration(timeInSeconds, "seconds").as(unit);
}
